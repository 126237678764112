import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { getToken, setToken } from '../observable/tokenObservable';
import { useNavigate } from 'react-router-dom';

const useAxiosFetch = (endPointUrl) => {
  const [data, setData] = useState([]);
  const [fetchError, setFetchError] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [status, setStatus] = useState(null)
  //const serverUrl = `http://13.48.177.86:8080/api/v1/`;
  const serverUrl = "http://38.242.200.246:8000/api/v1/";
  const userId = sessionStorage.getItem('userId');
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    const source = axios.CancelToken.source();
    //console.log('fetch useEffect') 
    const fetchData = async (url, token) => {
      setIsFetching(true);
      
      try {
        const response = await axios.get(url, {
          cancelToken: source.token,
          headers: {
            'Authorization': token
          }
        });
        
        if (isMounted) {
          setData(response.data);
          setStatus(response.status)
          setFetchError(null);
        }
      } catch (err) {
        if (isMounted) {
          if (err.response?.status === 401) {
            setFetchError('Error: Unauthorized Access');
            sessionStorage.clear();
            navigate('/login');
          } else if (err.response) {
            setFetchError(`Error: ${err.response.statusText}`);
          } else if (err.request) {
            setFetchError('No response received from server.');
          } else {
            setFetchError(err.message);
          }
          setData([]);
          setStatus(err?.response?.status ? err.response.status : 400)
        }
      } finally {
        if (isMounted) {
          setTimeout(() => setIsFetching(false), 2000);
        }
      }
    };

    const subscription = getToken().subscribe(token => {
        
      if (token && userId) {
        fetchData(serverUrl + endPointUrl, token);
      } else {
        
        
        sessionStorage.clear();
        
      }
    });

    return () => {
      isMounted = false;
      source.cancel();
      subscription.unsubscribe();
    };
  }, [endPointUrl, serverUrl, navigate]);

  useEffect(() => {
    toast.dismiss();
    if (isFetching) {
      toast.loading('Chargement des informations en cours...');
    } 
    
    
    
  }, [isFetching]);

  useEffect(() => {
    
    if (fetchError && !isFetching) {
      toast.dismiss();
     
    }
  }, [fetchError, isFetching]);

  return { data, fetchError, isFetching, status };
};

export default useAxiosFetch;
