import React from "react"
import { FaUserPlus, FaKey } from "react-icons/fa"
import InputGroup from 'react-bootstrap/InputGroup';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormControl from 'react-bootstrap/FormControl';
import { setToken } from "../../observable/tokenObservable";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


const AuthContentLogin = ({handleShow}) => {

    const navigate = useNavigate()
    //const serverUrl = `http://13.48.177.86:8080/api/v1`;
    const serverUrl = "http://38.242.200.246:8000/api/v1";

    
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('email is required'),
        password: Yup.string().required('Password is required'),
        rememberMe: Yup.boolean(),
    });

    
    const onSubmit = async (values, { setSubmitting }) => {

        const getDeviceId = () => {
            let deviceId = localStorage.getItem('deviceId');
            if (!deviceId) { 
                deviceId = uuidv4(); 
                localStorage.setItem('deviceId', deviceId); 
            } 
            return deviceId; 
        };
        const deviceId = getDeviceId();
        console.log("deviceid = " +deviceId)
        console.log(values)
        console.log("test")
        toast.dismiss()
        toast.loading('Connexion en cours...')
        try{
            const response = await axios.post(`${serverUrl}/auth/login`, 
                {   email: values.email,
                    password: values.password,
                    appareil: deviceId, // Inclure l'ID de l'appareil dans les paramètres de connexion
                },

                { headers: { 'Content-Type': 'application/json' },
                    withCredentials: true
                }
            );
            // sessionStorage.setItem("userId", response.data.id);
            await setToken(response.data.token, response.data.id)
            toast.dismiss()
            navigate('/')

        }catch(error){
            toast.dismiss()
            if (error.response) {
                toast.error(`Une erreur est survenenue pendant l'execution de l'operation`);
            } else if (error.request) {
                toast.error('Une erreur est surevenue pendant l\'execution de l\'operation');
            } else {
                toast.error('Une erreur est surevenue pendant l\'execution de l\'operation');
            }
        }
        setSubmitting(false); 
    };

    return (
        <Formik
            initialValues={{
                email: "",
                password: "",
                rememberMe: false
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({ values, handleSubmit, handleBlur, handleChange, errors, touched }) => (
                <Form className="d-flex flex-column gap-5" onSubmit={handleSubmit}>
                    <div>
                        <ErrorMessage name="email" component="div" className="red"/>
                        <ErrorMessage name="password" component="div" className="red"/>
                    </div>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1" className="p-3 fs-4">
                            <FaUserPlus />
                        </InputGroup.Text>
                        <Field
                            name="email"
                            as={FormControl}
                            placeholder="E-mail"
                            aria-label="E-mail"
                            aria-describedby="basic-addon1"
                            className="fs-4"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                        />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1" className="p-3 fs-4">
                            <FaKey />
                        </InputGroup.Text>
                        <Field
                            name="password"
                            type="password"
                            as={FormControl}
                            placeholder="Mot de passe"
                            aria-label="Mot de passe"
                            aria-describedby="basic-addon1"
                            className="fs-4"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.password}
                        />
                    </InputGroup>
                    <div className="d-flex justify-content-between flex-column flex-md-row gap-3">
                        <div>
                            <Field
                                name="rememberMe"
                                type="checkbox"
                                onChange={handleChange}
                                checked={values.rememberMe}
                            />
                            <label htmlFor="checkbox" className="ms-3">Se souvenir de moi </label>
                        </div>
                        <span className="fs-5 cursor-pointer" onClick={() => handleShow({option: 'motpasse'})}>Mot de passe oublie?</span>
                    </div>
                    <button type="submit" className="w-100 rounded-pill py-3 px-3 fs-4">
                        Se connecter
                    </button>
                </Form>
            )}
        </Formik>
    )
}

export default AuthContentLogin;
